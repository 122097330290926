@font-face {
    font-family: Poppins;
    src: url("../assets/font/Poppins-Bold.ttf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("../assets/font/Poppins-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}